/** VARIABLES
===================================*/

/** RESET AND LAYOUT
===================================*/

.bx-wrapper {
  position: relative;
  padding: 0;
  *zoom: 1;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.bx-wrapper img {
  max-width: 100%;
  display: block;
}

.bxslider {
  margin: 0;
  padding: 0;
}

ul.bxslider {
  list-style: none;
}

.bx-viewport {
  /*fix other elements on the page moving (on Chrome)*/
  -webkit-transform: translatez(0);
}

.bx-viewport>ul {
  padding: 0;
  margin: 0;
}

/** THEME
===================================*/

.bx-wrapper .bx-pager, .bx-wrapper .bx-controls-auto {
  width: 100%;
  margin: 0;
  padding: 0;
}

/* LOADER */

.bx-wrapper .bx-loading {
  min-height: 50px;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
}

/* PAGER */

.bx-wrapper .bx-pager {
  text-align: center;
}

.bx-wrapper .bx-pager.bx-default-pager button {
  margin: 0 5px;
  padding: 0;
}

.bx-wrapper .bx-pager-item, .bx-wrapper .bx-controls-auto .bx-controls-auto-item {
  display: inline-block;
  vertical-align: bottom;
  *zoom: 1;
  *display: inline;
}

/* DIRECTION CONTROLS (NEXT / PREV) */

.bx-wrapper .bx-prev {
  left: 1rem;
}

.bx-wrapper .bx-prev:hover, .bx-wrapper .bx-prev:focus {
  background-position: 0 0;
}

.bx-wrapper .bx-next {
  right: 1rem;
}

.bx-wrapper .bx-next:hover, .bx-wrapper .bx-next:focus {
  background-position: -43px 0;
}

.bx-wrapper .bx-controls-direction button {
  position: absolute;
  top: 50%;
  z-index: 1029;
  font-size: 2rem;
  transform: translateY(-50%);
}

.bx-wrapper .bx-controls-direction button.disabled {
  display: none;
}

.bx-controls button {
  background: transparent;
  border: none;
}
.bx-controls button:hover {
  cursor: pointer;
}

/* AUTO CONTROLS (START / STOP) */

.bx-wrapper .bx-controls-auto {
  text-align: center;
}

.bx-wrapper .bx-controls-auto .bx-start {
  display: block;
  margin: 0 5px;
}

.bx-wrapper .bx-controls-auto .bx-start:hover, .bx-wrapper .bx-controls-auto .bx-start.active, .bx-wrapper .bx-controls-auto .bx-start:focus {
  background-position: -86px 0;
}

.bx-wrapper .bx-controls-auto .bx-stop {
  display: block;
  margin: 0 5px;
}

.bx-wrapper .bx-controls-auto .bx-stop:hover, .bx-wrapper .bx-controls-auto .bx-stop.active, .bx-wrapper .bx-controls-auto .bx-stop:focus {
  background-position: -86px -33px;
}

/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
  text-align: center;
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
  position: absolute;
  bottom: 0;
  right: 2rem;
  width: 35px;
}

/* IMAGE CAPTIONS */

.bx-wrapper .bx-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #666;
  background: rgba(80, 80, 80, 0.75);
  width: 100%;
}

.bx-wrapper .bx-caption span {
  color: #fff;
  display: block;
  padding: 10px;
}
